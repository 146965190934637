import { faDownload, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Link, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ContractApi } from '../../../api';
import { Navbar } from '../../../components';
import { AppContext } from '../../../hooks/context';
import { loadTitle, setDocumentTitle } from '../../../utils/misc-utils';
import { convertDateToFormattedString } from '../../../utils/date-utils';
import ExportDialog from '../../../components/admin/export-dialog/export-dialog';

interface DataGridElement {
    id: string;
    username: string;
    isAdmin: boolean;
}

enum ExportStatus {
    PENDING = 'Ausstehend...',
    PROCESSING = 'Wird verarbeitet...',
    FINISHED = 'Abgeschlossen',
    ERROR = 'Fehler',
}

enum ExportContractType {
    LANDLINE = 'Festnetz',
    MOBILE = 'Mobilfunk',
    CONGSTAR = 'Congstar',
    FIBER = 'Glasfaser',
    ALL = 'Alle',
}

const ExportList = () => {
    const context = useContext(AppContext);
    const [rows, setRows] = useState<DataGridElement[]>([]);
    const [totalRowCount] = useState(10);
    const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const [sortModel, setSortModel] = useState<any[]>([
        { field: 'toDate', sort: 'desc' },
    ]);

    useEffect(() => {
        setDocumentTitle('Exportübersicht');
        return () => loadTitle();
    }, []);

    const { isLoading, error, data } = useQuery({
        queryKey: ['getExports'],
        queryFn: () => ContractApi.getExports(context.authToken),
        enabled: context.authToken !== undefined && context.isSuperadmin,
    });

    useEffect(() => {
        if (error) {
            console.error(error);
        } else if (data && data.data) {
            console.log(data);
            setRows(data.data);
        }
    }, [data, error]);

    const columns: GridColDef[] = [
        {
            field: 'status',
            headerName: 'Status',
            editable: false,
            flex: 1,
            valueFormatter: (params) => ExportStatus[params?.value],
        },
        {
            field: 'toDate',
            headerName: 'Monat',
            width: 250,
            editable: false,
            flex: 1,
            valueFormatter: (params) =>
                convertDateToFormattedString(params?.value, 4, 0, 'MM.YYYY'),
        },
        {
            field: 'Aktionen',
            headerName: '',
            flex: 1,
            hideable: false,
            sortable: false,
            renderHeader: () => (
                <Box>
                    {/* <Tooltip title="Export erstellen">
                        <IconButton
                            disableRipple
                            color="primary"
                            onClick={() => setExportDialogOpen(true)}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </IconButton>
                    </Tooltip> */}
                    <Tooltip title="Liste neuladen">
                        <IconButton
                            disableRipple
                            color="primary"
                            onClick={() =>
                                queryClient.invalidateQueries('getExports')
                            }
                            disabled={isLoading}
                        >
                            <FontAwesomeIcon icon={faRefresh} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
            renderCell: (params) => (
                <Box>
                    {params.row.urls && (
                        <>
                            {Object.keys(params.row.urls).map(
                                (key) =>
                                    params.row.urls[key].url && (
                                        <Tooltip
                                            key={key}
                                            title={ExportContractType[key]}
                                        >
                                            <Link
                                                href={params.row.urls[key].url}
                                                target="_blank"
                                            >
                                                <IconButton
                                                    disableRipple
                                                    color="info"
                                                    sx={{ height: 39 }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faDownload}
                                                    />
                                                </IconButton>
                                            </Link>
                                        </Tooltip>
                                    ),
                            )}
                        </>
                    )}
                </Box>
            ),
        },
    ];

    return (
        <Box>
            <Navbar title="Exportübersicht" />
            <Box sx={{ height: '100%', mr: 5, ml: 5 }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    loading={isLoading}
                    getRowId={(row) => row.id}
                    rowCount={totalRowCount}
                    disableColumnSelector
                    disableColumnFilter
                    disableColumnMenu
                    autoHeight
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                />
            </Box>
            <ExportDialog
                open={exportDialogOpen}
                onClose={() => setExportDialogOpen(false)}
            />
        </Box>
    );
};

export default ExportList;
