import {
    Box,
    Typography,
    Button,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    Alert,
    Link,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Navbar } from '../../components';
import { AppContext } from '../../hooks/context';
import { loadTitle, setDocumentTitle } from '../../utils/misc-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight, faShop } from '@fortawesome/free-solid-svg-icons';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { useQuery, useQueryClient } from 'react-query';
import { PointsApi } from '../../api';
import './component.css';
import moment from 'moment';
import { PointsHistoryChange } from '../../types/points';
import RoulettePro from 'react-roulette-pro';
import 'react-roulette-pro/dist/index.css';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';

const generateId = () =>
    `${Date.now().toString(36)}-${Math.random().toString(36).substring(2)}`;

const PrizeWheel = () => {
    const context = useContext(AppContext);

    const { width: windowWidth } = useWindowSize();

    const [mustSpin, setMustSpin] = useState<boolean>(false);
    const [spinning, setSpinning] = useState<boolean>(false);
    const [points, setPoints] = useState(0);
    const [availableSpins, setAvailableSpins] = useState<number>(0);
    const [winningOption, setWinningOption] = useState<number>(8);

    const [segmentsData, setSegmentsData] = useState<
        { segments: any[]; num: number; spinId: string } | undefined
    >(undefined);

    const [pointsHistoryOpen, setPointsHistoryOpen] = useState(false);
    const [pointsHistory, setPointsHistory] = useState([]);

    const [winningSegment, setWinningSegment] = useState<any>();
    const [isTestSpin, setIsTestSpin] = useState(false);

    const queryClient = useQueryClient();

    useEffect(() => {
        setDocumentTitle('Punktespiel');
        return () => loadTitle();
    }, []);

    useQuery({
        queryKey: ['getSegments'],
        queryFn: () => {
            return PointsApi.getSegments(context.authToken);
        },
        onSuccess: (data) => {
            setSegmentsData({
                ...data.data,
                num: data.data.num + 16,
                segments: segmentsData?.segments
                    ? segmentsData.segments
                    : [
                          ...data.data.segments,
                          ...data.data.segments,
                          ...data.data.segments,
                          ...data.data.segments,
                          ...data.data.segments,
                          ...data.data.segments,
                      ].map((s) => ({
                          ...s,
                          id: generateId(),
                      })),
            });
        },
        enabled: context.authToken !== 'undefined',
    });

    useEffect(() => {
        console.log(segmentsData);
    }, [segmentsData]);

    const { isLoading: spinsLoading } = useQuery({
        queryKey: ['getAvailableSpins'],
        queryFn: () => {
            return PointsApi.getAvailableSpins(context.authToken);
        },
        onSuccess: (data) => setAvailableSpins(data.data.spins),
        enabled: context.authToken !== 'undefined',
    });

    const { isLoading: pointsLoading } = useQuery({
        queryKey: ['getPoints'],
        queryFn: () => {
            return PointsApi.getPoints(context.authToken);
        },
        onSuccess: (data) => {
            setPoints(data.data.points);
            setPointsHistory(data.data.history);
        },
        enabled: context.authToken !== 'undefined',
    });

    const spin = async (testSpin: boolean) => {
        if ((availableSpins > 0 || testSpin) && segmentsData) {
            setSpinning(true);
            setWinningSegment(undefined);
            setMustSpin(false);
            if (!testSpin) {
                setIsTestSpin(false);
                await PointsApi.spinWheel(
                    context.authToken,
                    segmentsData.spinId,
                );
            } else {
                setIsTestSpin(true);
                await PointsApi.testSpinWheel(
                    context.authToken,
                    segmentsData.spinId,
                );
            }
            queryClient.invalidateQueries('getAvailableSpins');
            setMustSpin(true);
        }
    };

    const onStopSpinning = () => {
        setWinningSegment(segmentsData?.segments[winningOption]);
        queryClient.invalidateQueries('getPoints');
        setTimeout(() => {
            queryClient.invalidateQueries('getSegments');
            setSpinning(false);
            setMustSpin(false);
        }, 500);
    };

    useEffect(() => {
        if (segmentsData && !mustSpin) {
            setWinningOption(segmentsData.num);
        } else {
            setWinningOption(8);
        }
    }, [segmentsData]);

    return (
        <Box sx={{ mb: 10 }}>
            <Navbar title="Punktespiel" />
            <Box>
                <Box
                    display="flex"
                    sx={{
                        flexDirection: { xs: 'column-reverse', lg: 'row' },
                        justifyContent: 'center',
                        alignItems: 'top',
                    }}
                >
                    <Box
                        flex="1"
                        sx={{
                            width: { xs: '70%', lg: '5%' },
                            pl: { xs: 0, lg: 10 },
                            pr: { xs: 0, lg: 5 },
                            mt: { xs: 5 },
                            mr: { xs: 'auto', lg: 0 },
                            ml: { xs: 'auto', lg: 0 },
                            textAlign: 'center',
                        }}
                    >
                        <Typography variant="h4">
                            Punktestand:
                            <br />
                            {pointsLoading ? (
                                '...'
                            ) : (
                                <>
                                    {points} (~{' '}
                                    {(points / 10).toFixed(2).replace('.', ',')}
                                    €)
                                </>
                            )}
                        </Typography>
                        <a
                            href="https://my.con-corp.de/home/shop"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                variant="contained"
                                sx={{ color: '#fff' }}
                                startIcon={<FontAwesomeIcon icon={faShop} />}
                            >
                                Zum Shop
                            </Button>
                        </a>
                        <Typography variant="h5" sx={{ mt: 5, mb: 1 }}>
                            <b>
                                Drehungen übrig:{' '}
                                {spinsLoading ? '...' : availableSpins}
                            </b>
                        </Typography>
                        <Alert
                            severity="warning"
                            sx={{
                                mt: 2,
                                mb: 5,
                                width: '25%',
                                mr: 'auto',
                                ml: 'auto',
                            }}
                        >
                            Im Vormonat gesammelte Drehungen werden spätestens
                            in der ersten Woche des Folgemonats gutgeschrieben
                        </Alert>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {(segmentsData &&
                                segmentsData.segments.length === 0) ||
                            !segmentsData ? (
                                <CircularProgress />
                            ) : (
                                <>
                                    <div className="roulette-container">
                                        <RoulettePro
                                            prizes={segmentsData.segments}
                                            start={mustSpin}
                                            prizeIndex={winningOption}
                                            onPrizeDefined={() =>
                                                onStopSpinning()
                                            }
                                            spinningTime={5}
                                            defaultDesignOptions={{
                                                prizesWithText: true,
                                            }}
                                        />
                                        <div className="roulette-overlay-left"></div>
                                        <div className="roulette-overlay-right"></div>
                                    </div>
                                    {winningSegment && (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            sx={{
                                                color: '#000',
                                                animation: 'fadeIn .5s linear',
                                                position: 'absolute',
                                                backdropFilter: 'blur(10px)',
                                                zIndex: 10000,
                                                width: '96%',
                                                height: '240px',
                                                background:
                                                    'rgba(255,255,255,0.5)',
                                            }}
                                        >
                                            <Confetti
                                                width={windowWidth}
                                                height={240}
                                            />
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Typography variant="h3">
                                                    {isTestSpin &&
                                                        'Testdrehung - '}
                                                    Gewonnene Punkte:{' '}
                                                    {winningSegment.points}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </>
                            )}
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className="formBtn"
                                    sx={{
                                        mb: 2,
                                        color: '#fff',
                                        p: 2,
                                        mt: 3,
                                        width: '5%',
                                        mr: 'auto',
                                        ml: 'auto',
                                    }}
                                    startIcon={
                                        <FontAwesomeIcon icon={faRotateRight} />
                                    }
                                    onClick={() => spin(false)}
                                    disabled={spinning || availableSpins === 0}
                                >
                                    Drehen
                                </Button>
                            </>
                            <Link
                                href="#"
                                onClick={() => {
                                    if (!spinning) spin(true);
                                }}
                                sx={spinning ? { color: '#eaeaea' } : {}}
                            >
                                Testdrehung
                            </Link>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ mt: 5, mb: 5 }}
                        >
                            <Accordion
                                sx={{
                                    width: '15%',
                                }}
                                onClick={() =>
                                    setPointsHistoryOpen(!pointsHistoryOpen)
                                }
                                expanded={pointsHistoryOpen}
                            >
                                <AccordionSummary
                                    expandIcon={<GridExpandMoreIcon />}
                                >
                                    <Typography variant="h5">
                                        Meine Punkte
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {pointsHistory.map((h: any) => (
                                        <span key={h.id}>
                                            <>
                                                {moment(h.timestamp).format(
                                                    'DD.MM.YYYY | HH:mm',
                                                )}
                                                <br />
                                                <b>{h.points} Punkte</b>{' '}
                                                {PointsHistoryChange[h.change]}
                                                {h.reason ? (
                                                    <>
                                                        :<br />
                                                        <b>{h.reason}</b>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                <br />
                                                Neuer Punktestand: {h.newPoints}
                                            </>
                                            <Divider
                                                sx={{ mt: 1.5, mb: 1.5 }}
                                            />
                                        </span>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default PrizeWheel;
