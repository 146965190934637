import {
    Box,
    Card,
    CardContent,
    Typography,
    Link,
    Select,
    MenuItem,
    Badge,
    ListItemText,
    FormControl,
    InputLabel,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, NewsFeed } from '../../components';
import PermittedUsersStatusTable from '../../components/status/permitted-users-table';
import UserStatusCardContent from '../../components/status/user-status-card-content';
import { AppContext } from '../../hooks/context';
import { Regions } from '../../types/region';
import { Role } from '../../types/role';
import { loadTitle, setDocumentTitle } from '../../utils/misc-utils';
import { UserApi } from '../../api';

const Dashboard = () => {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const [regions, setRegions] = useState<string[]>([]);
    const [isDataComplete, setDataComplete] = useState<boolean>(false);

    useEffect(() => {
        if (context.authToken) {
            const getIsDataComplete = async () => {
                try {
                    const res = await UserApi.isDataComplete(context.authToken);
                    if (res) {
                        setDataComplete(res.data.isComplete);
                    }
                } catch (e) {}
            };
            getIsDataComplete();
        }
    }, [context.authToken]);

    useEffect(() => {
        setDocumentTitle('Dashboard');
        return () => loadTitle();
    }, []);

    useEffect(() => {
        if (context.isAdmin) {
            if (Array.isArray(context.region)) {
                setRegions(context.region);
            } else {
                setRegions(Object.keys(Regions));
            }
        }
    }, [context.isAdmin, context.region]);

    const [statusMonthSubtract, setStatusMonthSubtract] = useState(0);
    const [
        permittedUsersStatusMonthSubtract,
        setPermittedUsersStatusMonthSubtract,
    ] = useState(0);

    const [permittedUsersStatusRegion, setPermittedUsersStatusRegion] =
        useState<string>('no-value');

    return (
        <Box sx={{ mb: 10 }}>
            <Navbar title="Dashboard" />
            <Box
                display="flex"
                sx={{
                    flexDirection: { xs: 'column-reverse', lg: 'row' },
                    justifyContent: 'center',
                    alignItems: 'top',
                }}
            >
                <Box
                    flex="1"
                    sx={{
                        width: { xs: '70%', lg: '5%' },
                        pl: { xs: 0, lg: 10 },
                        pr: { xs: 0, lg: 5 },
                        mt: { xs: 5 },
                        mr: { xs: 'auto', lg: 0 },
                        ml: { xs: 'auto', lg: 0 },
                    }}
                >
                    <Card>
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Typography variant="h5">
                                Vertragsverwaltung
                            </Typography>
                            <hr />
                            {context.isAdmin && (
                                <Typography
                                    variant="h6"
                                    component="p"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    Alle Regionen
                                </Typography>
                            )}
                            <Link
                                onClick={() => navigate('/contracts/LANDLINE')}
                                color="#000"
                                sx={{ cursor: 'pointer' }}
                            >
                                <Typography variant="h6" component="p">
                                    Festnetz
                                </Typography>
                            </Link>
                            <Link
                                onClick={() => navigate('/contracts/MOBILE')}
                                color="#000"
                                sx={{ cursor: 'pointer' }}
                            >
                                <Typography variant="h6" component="p">
                                    Mobilfunk
                                </Typography>
                            </Link>
                            <Link
                                onClick={() => navigate('/contracts/CONGSTAR')}
                                color="#000"
                                sx={{ cursor: 'pointer' }}
                            >
                                <Typography variant="h6" component="p">
                                    Congstar
                                </Typography>
                            </Link>
                            <Link
                                onClick={() => navigate('/contracts/FIBER')}
                                color="#000"
                                sx={{ cursor: 'pointer' }}
                            >
                                <Typography variant="h6" component="p">
                                    Glasfaser
                                </Typography>
                            </Link>
                            {context.isAdmin &&
                                regions.map((r) => (
                                    <Box key={r} sx={{ mt: 2 }}>
                                        <Typography
                                            variant="h6"
                                            component="p"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {Regions[r]}
                                        </Typography>
                                        <Link
                                            onClick={() =>
                                                navigate(
                                                    `/contracts/LANDLINE?region=${r}`,
                                                )
                                            }
                                            color="#000"
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <Typography
                                                variant="h6"
                                                component="p"
                                            >
                                                Festnetz
                                            </Typography>
                                        </Link>
                                        <Link
                                            onClick={() =>
                                                navigate(
                                                    `/contracts/MOBILE?region=${r}`,
                                                )
                                            }
                                            color="#000"
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <Typography
                                                variant="h6"
                                                component="p"
                                            >
                                                Mobilfunk
                                            </Typography>
                                        </Link>
                                        <Link
                                            onClick={() =>
                                                navigate(
                                                    `/contracts/CONGSTAR?region=${r}`,
                                                )
                                            }
                                            color="#000"
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <Typography
                                                variant="h6"
                                                component="p"
                                            >
                                                Congstar
                                            </Typography>
                                        </Link>
                                        <Link
                                            onClick={() =>
                                                navigate(
                                                    `/contracts/FIBER?region=${r}`,
                                                )
                                            }
                                            color="#000"
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <Typography
                                                variant="h6"
                                                component="p"
                                            >
                                                Glasfaser
                                            </Typography>
                                        </Link>
                                    </Box>
                                ))}
                        </CardContent>
                    </Card>
                    <Card sx={{ mt: 5 }}>
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Typography variant="h5">
                                Benutzerbereich
                            </Typography>
                            <hr />
                            {/* <Badge
                                color="warning"
                                variant="dot"
                                invisible={isDataComplete}
                            >
                                <Link
                                    onClick={() => navigate('/user-space')}
                                    color="#000"
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <Typography variant="h6" component="p">
                                        Mein Bereich
                                    </Typography>
                                </Link>
                            </Badge> */}
                            <Link
                                onClick={() => navigate('/embed/billing')}
                                color="#000"
                                sx={{ cursor: 'pointer' }}
                            >
                                <Typography variant="h6" component="p">
                                    Abrechnungen
                                </Typography>
                            </Link>
                            <Link
                                color="#000"
                                sx={{ cursor: 'pointer' }}
                                href="https://salesacademy.telekom.de/sui/"
                                target="blank"
                            >
                                <Typography variant="h6" component="p">
                                    Sales Academy
                                </Typography>
                            </Link>
                        </CardContent>
                    </Card>
                    {context.isAdmin && (
                        <Card sx={{ mt: 5 }}>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <Typography variant="h5">
                                    Adminbereich
                                </Typography>
                                <hr />
                                <Link
                                    onClick={() => navigate('/admin/users')}
                                    color="#000"
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <Typography variant="h6" component="p">
                                        Benutzerverwaltung
                                    </Typography>
                                </Link>
                                <Link
                                    onClick={() => navigate('/admin/fpusers')}
                                    color="#000"
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <Typography variant="h6" component="p">
                                        Fokuspartner Übersicht
                                    </Typography>
                                </Link>
                                <Link
                                    onClick={() => navigate('/admin/news')}
                                    color="#000"
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <Typography variant="h6" component="p">
                                        Newsverwaltung
                                    </Typography>
                                </Link>
                                {context.isSuperadmin && (
                                    <Link
                                        onClick={() =>
                                            navigate('/sadmin/export')
                                        }
                                        color="#000"
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <Typography variant="h6" component="p">
                                            Exportübersicht
                                        </Typography>
                                    </Link>
                                )}
                            </CardContent>
                        </Card>
                    )}
                </Box>
                <Box
                    sx={{
                        pr: { xs: 0, md: 10 },
                        pl: { xs: 0, md: 5 },
                        mt: { xs: 5 },
                        width: { xs: '95%', md: '95%', lg: '70%' },
                    }}
                >
                    <Card>
                        <CardContent sx={{ alignItems: 'baseline' }}>
                            <Box flexDirection="row" display="flex">
                                <Typography
                                    variant="h5"
                                    sx={{ mr: 2, alignSelf: 'center' }}
                                >
                                    Mein Status
                                </Typography>
                                <Select
                                    value={statusMonthSubtract}
                                    onChange={(e) =>
                                        setStatusMonthSubtract(
                                            typeof e.target.value === 'number'
                                                ? e.target.value
                                                : parseInt(e.target.value),
                                        )
                                    }
                                >
                                    <MenuItem value={0}>diesen Monat</MenuItem>
                                    <MenuItem value={1}>letzten Monat</MenuItem>
                                    <MenuItem value={2}>
                                        vorletzten Monat
                                    </MenuItem>
                                </Select>
                            </Box>
                            <hr />
                            <UserStatusCardContent
                                monthSubtract={statusMonthSubtract}
                            />
                        </CardContent>
                    </Card>

                    <Card sx={{ mt: 10 }}>
                        <CardContent sx={{ alignItems: 'baseline' }}>
                            <Box flexDirection="row" display="flex">
                                <Typography
                                    variant="h5"
                                    sx={{ mr: 2, alignSelf: 'center' }}
                                >
                                    News
                                </Typography>
                            </Box>
                            <hr />
                            <NewsFeed />
                        </CardContent>
                    </Card>

                    {context.role && context.role !== Role.USER && (
                        <Card sx={{ mt: 10 }}>
                            <CardContent sx={{ alignItems: 'baseline' }}>
                                <Box flexDirection="row" display="flex">
                                    <Typography
                                        variant="h5"
                                        sx={{ mr: 2, alignSelf: 'center' }}
                                    >
                                        Teamstatus
                                    </Typography>
                                    <Select
                                        value={
                                            permittedUsersStatusMonthSubtract
                                        }
                                        onChange={(e) =>
                                            setPermittedUsersStatusMonthSubtract(
                                                typeof e.target.value ===
                                                    'number'
                                                    ? e.target.value
                                                    : parseInt(e.target.value),
                                            )
                                        }
                                    >
                                        <MenuItem value={0}>
                                            diesen Monat
                                        </MenuItem>
                                        <MenuItem value={1}>
                                            letzten Monat
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            vorletzten Monat
                                        </MenuItem>
                                    </Select>
                                    {context.isAdmin && (
                                        <FormControl
                                            sx={{ width: '10rem', ml: 1 }}
                                        >
                                            <InputLabel>Region</InputLabel>
                                            <Select
                                                label="Region"
                                                value={
                                                    permittedUsersStatusRegion
                                                }
                                                onChange={(e) =>
                                                    setPermittedUsersStatusRegion(
                                                        e.target.value,
                                                    )
                                                }
                                            >
                                                <MenuItem
                                                    key="DefaultRegion"
                                                    value="no-value"
                                                >
                                                    <ListItemText primary="Alle Regionen" />
                                                </MenuItem>
                                                {Object.keys(Regions).map(
                                                    (r) => (
                                                        <MenuItem
                                                            key={r}
                                                            value={r}
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    Regions[r]
                                                                }
                                                            />
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Box>
                                <hr />
                                <PermittedUsersStatusTable
                                    monthSubtract={
                                        permittedUsersStatusMonthSubtract
                                    }
                                    region={
                                        permittedUsersStatusRegion !==
                                        'no-value'
                                            ? permittedUsersStatusRegion
                                            : ''
                                    }
                                />
                            </CardContent>
                        </Card>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
