import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { appHistory } from '../utils/constants';
import { HistoryBrowserRouter } from '../utils/history-router';
import UnauthenticatedRoute from './unauthenticated-route';
import {
    Upload,
    Login,
    NewsList,
    // Leaderboards,
} from '../views/index';
import AuthenticatedRoute from './authenticated-route';
import Contracts from '../views/contracts/contracts';
import EditContract from '../views/contracts/edit-contract';
import UserList from '../views/admin/user/user-list';
import AdminRoute from './admin-route';
import Dashboard from '../views/dashboard/dashboard';
import SuperAdminRoute from './superadmin-route';
import ExportList from '../views/admin/export/export-list';
import PrizeWheel from '../views/prize-wheel/prize-wheel';
import BillingEmbed from '../views/embed/billing';
import FocusPartnerList from '../views/admin/focus-partner-list/focuspartner-list';

const App: FC = () => {
    return (
        <HistoryBrowserRouter history={appHistory}>
            <Routes>
                <Route path="*" element={<AuthenticatedRoute />}>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="backoffice" element={<Upload />} />
                    <Route path="upload" element={<Upload />} />
                    <Route path="contracts" element={<Contracts />} />
                    {/* <Route path="user-space" element={<UserSpace />} /> */}
                    <Route path="embed/billing" element={<BillingEmbed />} />
                    {/* <Route path="leaderboard" element={<Leaderboards />} /> */}
                    <Route path="prize-wheel" element={<PrizeWheel />} />
                    <Route
                        path="contracts/:contractType"
                        element={<Contracts />}
                    />
                    <Route
                        path="contract/:contractId"
                        element={<EditContract />}
                    />
                    <Route
                        path="contract/:contractId/:contractType"
                        element={<EditContract />}
                    />
                    <Route path="admin" element={<AdminRoute />}>
                        <Route path="fpusers" element={<FocusPartnerList />} />
                        <Route path="users" element={<UserList />} />
                        <Route path="news" element={<NewsList />} />
                    </Route>
                    <Route path="sadmin" element={<SuperAdminRoute />}>
                        <Route path="export" element={<ExportList />} />
                    </Route>
                    <Route path="*" element={<Navigate to="/dashboard" />} />
                </Route>
                <Route path="*" element={<UnauthenticatedRoute />}>
                    <Route path="login" element={<Login />} />
                </Route>
            </Routes>
        </HistoryBrowserRouter>
    );
};

export default App;
